import React from 'react';
import ContactForm from '../components/ContactForm';
import './Contact.css';

function Contact() {
  return (
    <ContactForm />
  );
}

export default Contact;
