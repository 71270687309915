import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Toggle the menu's open state
  };

  return (
    <header>
      <img src="./Images/BailamigosLogo.png" alt="Bailamigos Logo" className='headerLogo' />
      <nav className="navigation">
        {/* Hamburger menu for mobile */}
        <button className="hamburger" onClick={toggleMenu}>
          &#9776; {/* This is the three-line icon */}
        </button>

        {/* Mobile menu */}
        <div className={`mobile-menu ${menuOpen ? 'open' : ''}`}>
          <Link to="/" onClick={toggleMenu}>Hem</Link>
          <Link to="/courses" onClick={toggleMenu}>Kurser</Link>
          <Link to="/contact" onClick={toggleMenu}>Kontakt</Link>
          <Link to="/about" onClick={toggleMenu}>Om Oss</Link>
          <Link to="/fuerzadancekidz" onClick={toggleMenu}>FDK</Link>
          <Link className='anmalan' to="/registration" onClick={toggleMenu}>Anmäla till kurser</Link>
        </div>

        {/* Desktop menu */}
        <div className="menuBar">
          <Link to="/">Hem</Link>
          <Link to="/courses">Kurser</Link>
          <Link to="/contact">Kontakt</Link>
          <Link to="/about">Om Oss</Link>
          <Link to="/fuerzadancekidz">FDK</Link>
          <Link className='anmalan' to="/registration">Anmäla till kurser</Link>
        </div>
      </nav>
    </header>
  );
}

export default Header;