// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAlGAVjpva45VeI38-H13pO2u3meEQiKJ4",
    authDomain: "bailamigos-da6c0.firebaseapp.com",
    projectId: "bailamigos-da6c0",
    storageBucket: "bailamigos-da6c0.appspot.com",
    messagingSenderId: "112546672396",
    appId: "1:112546672396:web:f03eb8dd5588a0071c6329",
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
