import React from 'react';
import './About.css';

function About() {
  return (
    <div className='aboutContainer'>
      {/* About Bailamigos Section */}
      <div className='aboutText'>
        Bailamigos är en dansskola som erbjuder kurser i Bachata och Salsa.
        Vi har kurser för både barn och vuxna på alla nivåer, från nybörjare till avancerad.
        <br />
        Skolan grundades av Ricki Gonzales, en passionerad dansare och instruktör.
        Ricki har lång erfarenhet inom dansvärlden och är välkänd för sin unika stil och energi.
        För mer om Rickis arbete och inspiration, följ honom på Instagram.
      </div>

      {/* Instructor Section */}
      <div className='instructors'>
        {/* Ricki Gonzales */}
        <div className='instructorCard'>
          <img src='Images/instruktorer/Ricki.jpeg' alt='Ricki Gonzales' className='instructorImage' />
          <p className='instructorText'>
            Ricki Gonzales – Grundare och huvudinstruktör. Känd för sin personlighet, passionerade dansstil och långa erfarenhet inom dansvärlden.
          </p>
        </div>

        {/* Instructor 2 */}
        <div className='instructorCard'>
          <img src='Images/instruktorer/Jessika.jpg' alt='Jessika Ingram' className='instructorImage' />
          <p className='instructorText'>
            Jessika Ingram – En energisk och inspirerande dansare som specialiserar sig på både Bachata och Kizomba.
          </p>
        </div>

        {/* Instructor 3 */}
        <div className='instructorCard'>
          <img src='Images/instruktorer/Elin.jpg' alt='Ins 3' className='instructorImage' />
          <p className='instructorText'>
            Elin – En erfaren instruktör med ett unikt fokus på teknik och dansens uttryck.
          </p>
        </div>

          {/* Instructor 4 */}
          <div className='instructorCard'>
          <img src='Images/instruktorer/Sara.jpg' alt='Ins 3' className='instructorImage' />
          <p className='instructorText'>
            Sara Creutz – En erfaren instruktör med ett unikt fokus på teknik och dansens uttryck.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
