import React from 'react';
import './Fuerza.css';

function Fuerza() {
  return (
    <div className='fuerzaText'>
     This page is to for fuerza dance kidzs
    </div>
  );
}

export default Fuerza;
