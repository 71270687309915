import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Courses from './pages/Courses';
import About from './pages/About';
import Contact from './pages/Contact';
import Registration from './pages/Registration';
import Fuerza from './pages/Fuerza';
import './App.css';

function App() {
  return (
    <Router>
      <Header />
      <div className="app-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/fuerzadancekidz" element={<Fuerza />} />
          <Route path="/registration" element={<Registration />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;