import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <footer>
      <div className='social-buttons'>
        <a href="https://www.instagram.com/bailamigos_/" target="_blank" rel="noopener noreferrer">
          <button className='social-button'>
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </button>
        </a>
        <a href="https://www.facebook.com/ricki.gonzalez.14" target="_blank" rel="noopener noreferrer">
          <button className='social-button'>
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </button>
        </a>
        <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer">
          <button className='social-button'>
            <FontAwesomeIcon icon={faTiktok} size="2x" />
          </button>
        </a>
        <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
          <button className='social-button'>
            <FontAwesomeIcon icon={faYoutube} size="2x" />
          </button>
        </a>
      </div>
      <p className='footerText'>© 2024 Bailamigos. Alla rättigheter förbehållna.</p>
    </footer>
  );
}

export default Footer;
