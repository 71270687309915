import React from 'react';
import CourseList from '../components/CourseList';
import './Courses.css';

function Courses() {
  return (
    <div className='courses-container'>
      <CourseList />
    </div>
  );
}

export default Courses;