import React, { useState } from 'react';
import './RegistrationForm.css';
import { db } from '../firebase.js';
import { collection, setDoc, doc } from 'firebase/firestore';

function CourseForm() {
  const [formData, setFormData] = useState({
    category: 'singel', // Default to singel
    foreName: '',
    forePersonnummer: '',
    foreAddress: '',
    foreEmail: '',
    foljName: '',
    foljPersonnummer: '',
    foljAddress: '',
    foljEmail: '',
    kurs: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    // Basic validation (modify as needed)
    if (!formData.kurs) {
      alert('Vänligen välj en kurs!');
      return; // Exit the function if validation fails
    }

    // Determine the collection name based on the selected course
    const collectionName = formData.kurs; // Use kurs as the collection name
    const documentName = `${formData.foreName}-${formData.foljName}-${formData.category}`; // Create a unique document name

    try {
      alert('Initierar formulärsändning...');

      // Submit the form data to Firestore, including the document name
      const docRef = doc(collection(db, collectionName), documentName); // Specify the document reference
      await setDoc(docRef, { ...formData, documentName }); // Set the document with specified name

        alert('Formulär skickades framgångsrikt!');
        // Optionally, reset the form or redirect the user
        setFormData({
          category: 'singel',
          foreName: '',
          forePersonnummer: '',
          foreAddress: '',
          foreEmail: '',
          foljName: '',
          foljPersonnummer: '',
          foljAddress: '',
          foljEmail: '',
          kurs: '',
        });
    } catch (error) {
      console.error('Error during form submission:', error);
      alert('Ett oväntat fel inträffade. Vänligen försök igen.');
    }
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault(); // Prevent the default form submission
  
  //   // Basic validation (modify as needed)
  //   if (!formData.kurs) {
  //     alert('Vänligen välj en kurs!');
  //     return; // Exit the function if validation fails
  //   }
  
  //   // Determine the collection name based on the selected course
  //   const collectionName = formData.kurs;
  //   const documentName = formData.foreName + formData.foljName + formData.category;
    
  //   try {
  //     alert('Initierar formulärsändning...');
  
  //     // Example: Submit the form data along with the collection name
  //     const response = await submitFormData(collectionName, documentName, formData);
  
  //     // Handle response
  //     if (response.success) {
  //       alert('Formulär skickades framgångsrikt!');
  //       // Optionally, reset the form or redirect the user
  //       // resetForm();
  //     } else {
  //       alert('Fel vid sändning av formuläret. Försök igen senare.');
  //     }
  //   } catch (error) {
  //     console.error('Error during form submission:', error);
  //     alert('Ett oväntat fel inträffade. Vänligen försök igen.');
  //   }
  // };
  
  // // Placeholder function for submitting form data
  // const submitFormData = async (collectionName, data) => {
  //   // Replace this with your actual API call or logic
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       console.log(`Submitting to collection: ${collectionName}`, data); // Log collection name
  //       resolve({ success: true }); // Simulate successful submission
  //     }, 1000);
  //   });
  // };
  

  return (
    <form onSubmit={handleSubmit}>
      <div className="radio-group">
        <label className="radio-label">
          <input type="radio" name="category" value="singel" checked={formData.category === 'singel'} onChange={handleChange} />
          <span className="custom-radio"></span>
          Singel anmälan
        </label>
        <label className="radio-label">
          <input type="radio" name="category" value="par" checked={formData.category === 'par'} onChange={handleChange} />
          <span className="custom-radio"></span>
          Par anmälan
        </label>
      </div>

      {formData.category === 'singel' && (
        <div>
          {/* Singel Anmälan */}
          <div>
            <label>Namn</label>
            <input type="text" name="foreName" value={formData.foreName} onChange={handleChange} placeholder="Ange ditt namn" required />
          </div>
          <div>
            <label>Personnummer</label>
            <input type="text" name="forePersonnummer" value={formData.forePersonnummer} onChange={handleChange} placeholder="yyyymmddnnnn" required />
          </div>
          <div>
            <label>Adress</label>
            <input type="text" name="foreAddress" value={formData.foreAddress} onChange={handleChange} placeholder="Ange din adress" required />
          </div>
          <div>
            <label>Mejl</label>
            <input type="email" name="foreEmail" value={formData.foreEmail} onChange={handleChange} placeholder="Ange din mejl" required />
          </div>
        </div>
      )}

      {formData.category === 'par' && (
        <div>
          {/* Förare (Driver) Information */}
          <h3 className='LabelParAnmälan'>Förare Information</h3>
          <div>
            <label>Förares Namn</label>
            <input type="text" name="foreName" value={formData.foreName} onChange={handleChange} placeholder="Förares namn" required />
          </div>
          <div>
            <label>Personnummer</label>
            <input type="text" name="forePersonnummer" value={formData.forePersonnummer} onChange={handleChange} placeholder="yyyymmddnnnn" required />
          </div>
          <div>
            <label>Adress</label>
            <input type="text" name="foreAddress" value={formData.foreAddress} onChange={handleChange} placeholder="Förares adress" required />
          </div>
          <div>
            <label>Mejl</label>
            <input type="email" name="foreEmail" value={formData.foreEmail} onChange={handleChange} placeholder="Förares mejl" required />
          </div>

          {/* Följare (Follower) Information */}
          <h3 className='LabelParAnmälan'>Följare Information</h3>
          <div>
            <label>Följares Namn</label>
            <input type="text" name="foljName" value={formData.foljName} onChange={handleChange} placeholder="Följares namn" required />
          </div>
          <div>
            <label>Personnummer</label>
            <input type="text" name="foljPersonnummer" value={formData.foljPersonnummer} onChange={handleChange} placeholder="yyyymmddnnnn" required />
          </div>
          <div>
            <label>Adress</label>
            <input type="text" name="foljAddress" value={formData.foljAddress} onChange={handleChange} placeholder="Följares adress" required />
          </div>
          <div>
            <label>Mejl</label>
            <input type="email" name="foljEmail" value={formData.foljEmail} onChange={handleChange} placeholder="Följares mejl" required />
          </div>
        </div>
      )}

      <div className='kursSeparation'>
        <label>Kurs</label>
        <select name="kurs" value={formData.kurs} onChange={handleChange} required>
          <option value="">Välj kurs</option>
          <option value="bachataNybörjare">Bachata nybörjare</option>
          <option value="bachataForts1">Bachata fortsättning del 1</option>
          <option value="bachataForts2">Bachata fortsättning del 2</option>
        </select>
      </div>
      <button type="submit">Anmäla till kursen</button>
    </form>
  );
}

export default CourseForm;
