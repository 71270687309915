import React from 'react';
import './VideoPlayer.css';

const VideoPlayer = ({ link, className }) => {
  return (
    <div className={`video-container ${className}`}>
      <video className="video-player" controls>
        <source src={link} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;