import React from 'react';
import './CourseList.css';

// const CurrentCourses = [
//   { id: 1, name: 'Bachata nybörjare', day: 'Tisdagar', time: '18:00 - 19:30', place: 'Norra promenaden 108', dates: ["03/09", "10/09", "17/09", "24/09", "01/10", "08/10"]},
//   { id: 2, name: 'Bachata fortsättning del 1', day: 'Tisdagar', time: '19:30 - 21:00', place: 'Norra promenaden 108', dates: ["03/09", "10/09", "17/09", "24/09", "01/10", "08/10"]}
// ];

const CurrentCourses = [
  { id: 1, name: 'Bachata nybörjare', day: 'Onsdagar', time: '18:45 - 20:15', place: 'Lindövägen 8', dates: ["16/10", "23/10", "30/10", "06/11", "13/11", "20/11"] },
  { id: 2, name: 'Bachata fortsättning del 1', day: 'Tisdagar', time: '18:00 - 19:30', place: 'Snickaregatan 2', dates: ["15/10", "22/10", "05/11", "12/11", "19/11", "26/11"] },
  { id: 3, name: 'Bachata fortsättning del 2', day: 'Tisdagar', time: '19:40 - 21:10', place: 'Snickaregatan 2', dates: ["15/10", "22/10", "05/11", "12/11", "19/11", "26/11"] }
];

const UpcomingCourses = []

const groupByDay = (courses) => {
  return courses.reduce((grouped, course) => {
    const day = course.day;
    if (!grouped[day]) grouped[day] = [];
    grouped[day].push(course);
    return grouped;
  }, {});
};

function renderCoursesByDay(courses) {
  const groupedCourses = groupByDay(courses);

  return Object.keys(groupedCourses).map((day, index) => (
    <div key={index} className="course-item">
      <h5>{day}</h5>
      <div className="course-list">
        {groupedCourses[day].map((course) => (
          <div key={course.id} className="course-detail">
            <div className='kursDetalj' >Kurs: {course.name}</div>
            <div className='kursDetalj' >Tid: {course.time}</div>
            <div className='kursDetalj' >Plats: {course.place}</div>
            <div className='kursDetalj' >Kurs Tillfällen:</div>
            <div className="date-container">
              {course.dates.map((date, i) => (
                <span key={i} className="date-circle">{date}</span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  ));
}

function CourseList() {
  return (
    <div className='course-container'>
      <div className='current-courses'>
        <h2 className='course-list-title'>Nuvarande Kurser</h2>
        {renderCoursesByDay(CurrentCourses)}
      </div>

      <div className='upcoming-courses'>
        <h2 className='course-list-title'>Kommande Kurser</h2>
        {renderCoursesByDay(UpcomingCourses)}
      </div>
    </div>
  );
}

export default CourseList;
