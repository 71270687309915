import React from 'react';
import './Home.css';
import Album from '../components/Album';
import VideoPlayer from '../components/VideoPlayer'; // Adjust the path accordingly
import CountdownTimer from '../components/CountDown';

function Home() {
  return (
    <div className='home-container'>
      <h1 className='text-big'>Nedräkningen för dansfestivalen i april</h1>
      <CountdownTimer targetDate="2025-04-11T23:59:59" />
      <div className='description-text'>
        <p>
          Bailamigos är en modern dansskola som erbjuder kurser i Bachata och Salsa. Vi har kurser för alla, från nybörjare till avancerad, för både barn och vuxna.
        </p>
        <p>
          Skolan grundades av Ricki Gonzales, en passionerad dansare med lång erfarenhet inom dansvärlden. Följ Ricki på Instagram för mer inspiration.
        </p>
      </div>

      <div>
      <h1>Bilder från festen</h1>
      <Album />
      </div>

      <div>
        <h1> Video från olika aktiviteter</h1>
        {/* Horizontal Video */}
        <VideoPlayer link={'Videos/eventCenter.mp4'} className="horizontal-video"/>
        </div>
        {/* Video Row with Vertical Videos */}
        <div className="videoRow">
          <VideoPlayer link={'Videos/kursVideo.mp4'} className="vertical-video"/>
          <VideoPlayer link={'Videos/eventCenterVertical.mp4'} className="vertical-video"/>
          <VideoPlayer link={'Videos/kursHageby.mp4'} className="vertical-video"/>
        </div>
      

    </div>

  );
}

export default Home;