import React from 'react';
import './Album.css'; // Import the CSS file for styling

const images = [
  '/Images/image1.jpeg',
  '/Images/image2.jpeg',
  '/Images/image3.jpeg',
  '/Images/image4.jpeg',
  '/Images/image5.jpeg',
  '/Images/image6.jpeg',
  '/Images/image7.jpeg',
  '/Images/image8.jpeg',
  '/Images/image9.jpeg',
  '/Images/image10.jpeg',
  '/Images/image11.jpeg',
  '/Images/image12.jpeg',
  '/Images/image13.jpeg',
  '/Images/image14.jpeg',
  '/Images/image15.jpeg',
  '/Images/image16.jpeg',
  '/Images/image17.jpeg',
  '/Images/image18.jpeg',
  '/Images/image19.jpeg',
  '/Images/image20.jpeg',
  '/Images/image21.jpeg',
  '/Images/image22.jpeg',
  '/Images/image23.jpeg',
  '/Images/image24.jpeg',
  '/Images/image25.jpeg',
  '/Images/image26.jpeg',
  '/Images/image27.jpeg',
  '/Images/image28.jpeg',
  '/Images/image29.jpeg',
  '/Images/image30.jpeg',
  '/Images/image31.jpeg',
  '/Images/image32.jpeg',
  '/Images/image33.jpeg',
  '/Images/image34.jpeg',
  '/Images/image35.jpeg',
  '/Images/image36.jpeg'
];

const Album = () => {
  return (
  <div>
  <div className="album-container">
      {images.map((image, index) => (
        <div key={index} className="album-item">
          <img src={image} alt={`Album ${index + 1}`} className="albumImage" />
        </div>
      ))}
    </div>

</div>
  );
};

export default Album;
