import React from 'react';
import RegistrationForm from '../components/RegistrationForm';
import './Registration.css';

function Registration() {
  return (
      <RegistrationForm />
  );
}

export default Registration;
