import React, { useState } from 'react';
import './ContactForm.css';
import emailjs from 'emailjs-com';

function ContactForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: name,
      from_email: email,
      message: message,
    };

    emailjs
      .send('service_dcar5k9', 'template_82hshyg', templateParams, 'PxQxon_myzh0lHMzU')
      .then(
        (response) => {
          setStatus('Message sent successfully!');
          setTimeout(() => {
            setStatus('');
            setName('');
            setEmail('');
            setMessage('');
          }, 3000);
        },
        (error) => {
          setStatus('Failed to send message.');
        }
      );
  };

  return (
    <form className='formContact' onSubmit={handleSubmit}>
      <div className='elementForm'>
        <label>Namn:</label>
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
      </div>
      <div className='elementForm'>
        <label>E-post:</label>
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
      </div>
      <div className='elementForm'>
        <label>Meddelande:</label>
        <textarea value={message} onChange={(e) => setMessage(e.target.value)} required />
      </div>
      <button type="submit">Skicka meddelande </button>
      {status === '' && <p></p>}
      {status === 'Failed to send message.'&& <p className='error'>{status}</p>}
      {status === 'Message sent successfully!'&& <p className='success'>{status}</p>}
    </form>
  );
}

export default ContactForm;
